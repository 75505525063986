<script>
  import Skills from "./Skills.svelte";
</script>

<div class="about-block">
  <h1 class="text-5xl text-stone-900 dark:text-white m-4">About</h1>

  <!-- <div id="description" class="text-xl md:text-3xl text-stone-900 dark:text-white m-20 md:text-justify">
    I'm a Software Engineer with two years of experience in web development. I
    enjoy building web and mobile applications. Currently, I am pursuing my
    master's in Computer Engineering(Computer Systems) at Ira A Fulton School of
    Engineering, Arizona State University. I am actively looking for Software
    Engineering Internships for Summer-2022.
  </div> -->
  <div
    id="description"
    class="text-xl md:text-3xl text-stone-900 dark:text-white mt-20 mx-10 md:mx-20 md:text-justify"
  >
    Welcome to my personal website! I am a computer engineer with extensive
    experience in software development and design. In my previous work
    experience, I have developed a variety of applications and tools using a
    range of technologies and frameworks.
  </div> 
  <div
    id="description"
    class="text-xl md:text-3xl text-stone-900 dark:text-white mt-5 mx-10 md:mx-20 md:text-justify"
  >
    I am well-versed in various programming languages, including JavaScript,
    Typescript, Python, C++, Java, Dart, and C#. I have experience working with
    databases such as MySQL and MongoDB, and I have expertise in working with
    various operating systems, including Linux, Android, and Windows.
  </div>
  <div
    id="description"
    class="text-xl md:text-3xl text-stone-900 dark:text-white mt-5 mx-10 md:mx-20 md:text-justify"
  >
    I am proficient in using version control tools like Git, and I have
    experience in developing applications using frameworks like Svelte.js,
    Django, .NET, and Flutter. In my work experience, I have also designed and
    developed Angular test harness environment for cypress testing framework and
    improved the load speed of JavaScript files in the browser by 30% by
    adopting advanced loading methods using webpack.
  </div>
  <div
    id="description"
    class="text-xl md:text-3xl text-stone-900 dark:text-white mt-5 mx-10 md:mx-20 md:text-justify"
  >
    Overall, I am a skilled and experienced software developer with expertise in
    a wide range of technologies and frameworks. I look forward to applying my
    knowledge and experience to create exceptional software solutions for my
    clients.
  </div>
  <Skills />
</div>

<style>
  .about-block {
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin: 20px;
  }
</style>
